/* :root{
    --theme-img-bg-base--2: unset;
    --theme-color-bg-base--2: #194C38;
    font-size: 100%;
}

.page-sidebar-fixed.page-container {
    background-image: var(--theme-img-bg-base--2) !important;
    background-color: var(--theme-color-bg-base--2) !important;
    color: #fff;
} */
/* html{
    max-width: unset;
} */
/* ///dashboard2 desktop/// */
.desktop-dashboard2-header{
    background: #22674b;
    border-bottom: 0.01rem solid #2A815F;
    padding: 3px 10px 3px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* position: fixed; */
    width: 100%;
    z-index: 1;
    font-size: 11px;
    font-weight: 300;
}
.desktop-dashboard2-header .first{
    display: flex;
    align-items: center;
    column-gap: 5px;
}
.desktop-dashboard2-header .second{
    display: flex;
    align-items: center;
    column-gap: 5px;
}
.desktop-dashboard2-header .desktop-music-box{
    text-align: center;
}
.desktop-d2-dropdown-container{
    border: thin solid #2A815F;
    display: flex;
    border-radius: 100px;
    align-items: center;
    column-gap: 5px;
    /* width: 55px; */
    font-size: 14px;
    padding: 3px 12px;
}
.desktop-d2-dropdown-icon-container{
    border: thin solid #2A815F;
    display: flex;
    border-radius: 100px;
    align-items: center;
    column-gap: 5px;
    /* width: 55px; */
    font-size: 14px;
    padding: 3px 12px 3px 3px;
}
.desktop-d2-icon-border{
 border: 1px solid #F6FB51;
 border-radius: 100px;
}
.desktop-d2-icon-size2 {
    display: inline-block;
    width: 23px;
    min-width: 23px;
    height: 23px;
    min-height: 23px;
}
.d2-login-register-container{
    color: #facf20;
    font-size: 14px;
}
.d2-login-register-container span{
    color: #c5e2d2;
}
.desktop-content-container{
    max-width: 1100px;
    margin: auto;
}
.d2-announcement-container{
    display: flex;
    align-items: center;
    grid-column-gap: 5px;
    column-gap: 5px;
    background: #22674b;
    padding: 15px;
    border-radius: 10px;
}
.d2-announcement{
    line-height: 1;
}
.desktop-d2-body-container{
    background: #22674b;
    padding: 15px;
    border-radius: 10px;
}
.desktop-popular-tab-container{
    display: flex;
    align-items: self-start;
    justify-content: space-between;
}
.desktop-tab-icons-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 5px;
    max-width: 500px;
    width: 500px;
    border-bottom: 1px solid #2A815F;
   
}
.desktop-tab-icons-box .tab-items{
    display: flex;
    align-items: center;
    column-gap: 5px;
    position: relative;
    padding-bottom: 15px;
}

.desktop-tab-icons-box .tab-items.active:after{
    content: ' ';
    width: 100%;
    height: 2px;
    background: #FACF20;
    position: absolute;
    bottom: -2px;
    left: 0px;
    border-radius: 100px;
}
.d2-hot-games-container{
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(10%, 1fr) );
    justify-items: center;
    grid-gap: 20px;
}
.d2-hot-game-item{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}
.d2-hot-game-item h6{
    font-size: 14px;
    margin-top: 8px;
}
.d2-hot-more-details .first{
    color: #7db39e;
}
.d2-hot-more-details .second{
    color: #c5e2d2;
}
.desktop-d2-border-section{
    border-bottom: 1px solid #2A815F;
    padding-bottom: 30px;
    margin-bottom: 30px;
}
/* ///dashboard2 mobile/// */

.dashboard2-header{
    background: #22674b;
    border-bottom: 0.01rem solid #2A815F;
    padding: 3px 10px 3px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    z-index: 1;
}
.dashboard2-header .second{
    display: flex;
    align-items: center;
    column-gap: 20px;
}
.dashboard2-header .second>div{
    text-align: center;
}
.db2-content-with-header{
    padding-top: 49px;
    width: 95%;
    font-size: 11px;
    font-weight: 300;
}
.d2-icon-size{
    display: inline-block;
    width: 23px;
    min-width: 23px;
}
.d2-icon-size2{
    display: inline-block;
    width: 20px;
    min-width: 20px;
}
.d2-icon-size3{
    display: inline-block;
    width: 30px;
    min-width: 30px;
}
h6{
    font-weight: 300;
    margin-bottom: 0px;
}
.d2-banner{
    min-height: unset;
}
.d2-banner .slick-dots{
    background: transparent;
    bottom: 15px;
}
.d2-banner .slick-dots li button {
    background: #ffffffad;
    padding: 0px;
    border-radius: 5px;
    width: 6px;
    height: 6px;
    line-height: 0;
    margin: 0px 5px;
}
.d2-banner .slick-dots li.slick-active button{
    background: #fff;
    padding: 0px;
    border-radius: 5px;
    width: 6px;
    height: 6px;
}
.d2-currency-container{
    border: thin solid #2A815F;
    display: flex;
    border-radius: 100px;
    align-items: center;
    column-gap: 5px;
    width: 55px;
}
.d2-icon-border{
 border: 1px solid #F6FB51;
 border-radius: 100px;
}
.d2-under-announcement-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.d2-under-announcement-container .second{
    display: flex;
    align-items: flex-start;
    column-gap: 20px;
}
.d2-under-announcement-container .second>div{
    text-align: center;
}
.d2-side-menu-parent{
    flex-direction: column;
    justify-content: flex-start;
    display: flex;
    align-items: flex-start;
    row-gap: 10px;
}
.d2-side-menu-item{
    display: flex;
    align-items: center;
    /* column-gap: 15px; */
    max-width: 90px;
    background: #1a704d;
    height: 35px;
    width: 100%;
    padding: 0px 5px;
    border-radius: 6px;
}
.d2-side-menu-item.active{
    background: #FACF20;
}
.d2-side-menu-item h6{
    font-size: 12px;
    color: #C5E2D2;
    width: 100%;
    text-align: center;
}
.d2-side-menu-item.active h6{
    color: #000;
}
.d2-main-content-container{
    display: flex;
    /* justify-content: space-between; */
    column-gap: 10px;
}
.d2-main-content-container .right{
    width: 100%;
}
.d2-hot-tab-parent{
    display: flex;
    justify-content: space-between;
}
.d2-hot-tab{
    display: flex;
    width: 100%;
    flex: 1;
    /* justify-content: center; */
    border-bottom: 1px solid #2a815f;
}
.d2-hot-tab-item{
    display: flex;
    justify-content: center;
    width: 100%;
}
.d2-hot-tab-item h6{
    /* color: #facf20; */
    border-bottom: 1px solid transparent;
    padding: 5px 0px;
    font-size: 11px;
}
.d2-hot-tab-item.active h6{
    color: #facf20;
    border-bottom: 1px solid #facf20;
    padding: 5px 0px;
}

.d2-games-section{
    border-top: 1px solid #2a815f;
    padding: 15px 0px;
}
.d2-games-title-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.d2-games-title-box .first{
    display: flex;
    align-items: center;
    font-size: 14px;
    column-gap: 5px;
}
.d2-games-title-box .second{
    color: #7db39e;
    font-size: 13px;
}
.d2-game-list-item{
    background-image: url(../img/dashboard2/game-bg.png);
    background-size: 100% 100%;
    border-radius: 10px;
    border: 1px solid #2A815F;
    height: 100px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding: 10px 20px;
    
}
.d2-game-list-container{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}
.d2-game-list-item .first{
    display: flex;
    align-items: center;
    column-gap: 5px;
}
.d2-game-list-item .first h6{
    font-size: 12px;
}
.d2-game-list-item-parent{
    position: relative;
}
.d2-maintenance-container{
    background: #00000080;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    align-items: center;
}
.d2-maintenance-container .d2-maintenance-imt{
    width: 45px;
}
.d2-maintenance-text{
    font-size: 16px;
    font-weight: bold;
}